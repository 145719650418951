<template>
  <div>
    <footer style="padding-top: 5px; margin-bottom: 1em">
      <div class="has-text-centered has-background-white-ter">
        <slot></slot>
        <p v-if="auth">
          v{{ version }} -
          <a @click="showReleaseNotes()">{{ $t('Release notes') }}</a>
        </p>
      </div>
    </footer>
    <b-modal :active.sync="showRelNotes" has-modal-card>
      <release-notes></release-notes>
    </b-modal>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: {
    ReleaseNotes: () =>
      import(/* webpackPrefetch: true */ '@/components/admin/ReleaseNotes'),
  },
  computed: {
    showRelNotes: {
      get() {
        return this.$store.getters['storeReleases/shouldShowRelease'];
      },
      set(val) {
        this.$store.dispatch('storeReleases/setViewRelease', val);
      },
    },
  },
  methods: {
    showReleaseNotes() {
      this.$store.dispatch('storeReleases/setViewRelease', true);
    },
  },
});
</script>
